var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "unitHelpEdit" },
    [
      _c(
        "el-form",
        {
          ref: "msForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            "label-position": "right",
            model: _vm.msForm,
            rules: _vm.rules
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "70px",
                    label: "开展年份：",
                    prop: "certificateDate"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      "value-format": "yyyy",
                      type: "year",
                      placeholder: "请选择"
                    },
                    model: {
                      value: _vm.msForm.certificateDate,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "certificateDate", $$v)
                      },
                      expression: "msForm.certificateDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "70px",
                    label: "任务主题：",
                    prop: "organName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "350px !important" },
                    attrs: { placeholder: "请输入", clearable: "" },
                    model: {
                      value: _vm.msForm.organName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "organName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.organName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "70px",
                    label: "任务内容：",
                    prop: "auditOpinion"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-textarea",
                    staticStyle: { width: "640px !important" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 7, maxRows: 7 },
                      resize: "none",
                      maxlength: "180",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    model: {
                      value: _vm.msForm.auditOpinion,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "auditOpinion", $$v)
                      },
                      expression: "msForm.auditOpinion"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c("attachmen-upload", {
                attrs: {
                  folder: "/bussiness/unitHelp",
                  label: "通知文件：",
                  labelwidth: "70px",
                  limit: 5,
                  fileProp: "fileList"
                },
                model: {
                  value: _vm.fileList,
                  callback: function($$v) {
                    _vm.fileList = $$v
                  },
                  expression: "fileList"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "任务周期：",
                    "label-width": "70px",
                    prop: "reportDateStart"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      "picker-options": _vm.$validate.noBigDateNoOverToday(
                        _vm.$data["msForm"],
                        "completionDateEnd"
                      ),
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "开始时间"
                    },
                    model: {
                      value: _vm.msForm.reportDateStart,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "reportDateStart", $$v)
                      },
                      expression: "msForm.reportDateStart"
                    }
                  })
                ],
                1
              ),
              _c("span", { staticClass: "split" }, [_vm._v("~")]),
              _c(
                "el-form-item",
                { attrs: { prop: "reportDateEnd" } },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      "picker-options": _vm.$validate.noSmallDateNoOverToday(
                        _vm.$data["msForm"],
                        "completionDateBegin"
                      ),
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "结束时间"
                    },
                    model: {
                      value: _vm.msForm.reportDateEnd,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "reportDateEnd", $$v)
                      },
                      expression: "msForm.reportDateEnd"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "70px",
                    label: "发布单位：",
                    prop: "fbunit"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "350px !important" },
                    attrs: { placeholder: "请输入", clearable: "" },
                    model: {
                      value: _vm.msForm.fbunit,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "fbunit",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.fbunit"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "70px",
                    label: "发布日期：",
                    prop: "fbdate"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "请选择"
                    },
                    model: {
                      value: _vm.msForm.fbdate,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "fbdate", $$v)
                      },
                      expression: "msForm.fbdate"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit(true)
                }
              }
            },
            [_vm._v("发布")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("暂存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }