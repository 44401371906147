<template>
  <div class="unitHelpEdit">
    <el-form class="zwx-form edit-form" ref="msForm" label-position="right" :model="msForm" :rules="rules" @submit.native.prevent>
      <div>
        <el-form-item label-width="70px" label="开展年份：" prop="certificateDate">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width:200px !important" v-model="msForm.certificateDate" value-format="yyyy" type="year" placeholder="请选择" />
        </el-form-item>
        <el-form-item label-width="70px" label="任务主题：" prop="organName">
          <el-input style="width: 350px !important" class="zwx-input" v-model.trim="msForm.organName" placeholder="请输入" clearable />
        </el-form-item>
      </div>
      <div>
        <el-form-item label-width="70px" label="任务内容：" prop="auditOpinion">
          <el-input class="zwx-textarea" type="textarea" v-model="msForm.auditOpinion" style="width: 640px !important;" :autosize="{ minRows: 7, maxRows: 7 }" resize="none" maxlength="180" placeholder="请输入" clearable />
        </el-form-item>
      </div>
      <div>
        <attachmen-upload folder="/bussiness/unitHelp" v-model="fileList" label="通知文件：" labelwidth="70px" :limit="5" fileProp="fileList" />
      </div>
      <div>
        <el-form-item label="任务周期：" label-width="70px" prop="reportDateStart">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" :picker-options="$validate.noBigDateNoOverToday($data['msForm'], 'completionDateEnd')" v-model="msForm.reportDateStart" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="开始时间" />
        </el-form-item>
        <span class="split">~</span>
        <el-form-item prop="reportDateEnd">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" :picker-options="$validate.noSmallDateNoOverToday($data['msForm'], 'completionDateBegin')" v-model="msForm.reportDateEnd" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="结束时间" />
        </el-form-item>
      </div>
      <div>
        <el-form-item label-width="70px" label="发布单位：" prop="fbunit">
          <el-input style="width: 350px !important" class="zwx-input" v-model.trim="msForm.fbunit" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label-width="70px" label="发布日期：" prop="fbdate">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width:200px !important" v-model="msForm.fbdate" value-format="yyyy-MM-dd" type="date" placeholder="请选择" />
        </el-form-item>
      </div>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="submit(true)">发布</el-button>
      <el-button class="zwx-button zwx-button-32" @click="submit()">暂存</el-button>
    </fixed-footer>
  </div>
</template>
<script>
export default {
  name: 'UnitHelpEdit',
  data() {
    return {
      msForm: {
        rid: this.$route.params.rid,
        certificateDate: '',
        organName: '',
        auditOpinion: '',
        fileList: [],
        completionDateEnd: '',
        completionDateBegin: '',
        fbunit: '',
        fbdate: '',
      },
      rules: {
        completionDateBegin: [{ required: true, message: '请选择开始时间', trigger: ['change', 'blur'] }],
        completionDateEnd: [{ required: true, message: '请选择结束时间', trigger: ['change', 'blur'] }],
      },
    }
  },
  created() {
    // this.detail()
  },
  methods: {
    submit() {},
    detail() {
      let data = {
        rid: this.rid,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/workplace/detection/msSelectRectifyExpAll-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          this.addForm.rid = data.result.rid
          if (data.type === '00') {
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        this.error
      )
    },
    save(isSubmit) {
      let data = { ...this.msForm }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/workplace/detection/addOrModifyDetectionRectify-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            if (isSubmit) {
              this.$router.push({ name: 'UnitHelpList', fresh: true })
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        this.error
      )
    },
  },
  error(data) {
    this.$emit('loading', false)
    this.$system.notify('错误', data.mess, 'error')
  },
}
</script>
<style lang="less" scoped>
.unitHelpEdit {
  padding: 20px;
}
.split {
  position: relative;
  left: -9px;
}
</style>
