<template>
  <div></div>
</template>
<script>
export default {
  name: 'SinglePoint',
  data() {
    return {
      tokenId: this.$route.query.tokenId,
    }
  },
  created() {
    this.$zwxCookie.addCookie('msTokenId', this.tokenId)
    window.localStorage.setItem('loginState', 'true')
    this.$router.push({ path: `/default` })
  },
  methods: {},
}
</script>
<style lang="scss" scoped></style>
